var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "menu" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "dark", variant: "dark" } },
        [
          _c("b-navbar-brand", { attrs: { href: "/" } }, [
            _c("img", {
              attrs: { src: _vm.favicon, height: "24", width: "24" },
            }),
            _c("span", { staticClass: "ml-1" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                _vm._l(_vm.elements, function (el) {
                  return !el.hide
                    ? _c(
                        "div",
                        { key: el.title },
                        [
                          _vm.isNavbarItem(el)
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: { href: el.href, active: el.active },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClick(el)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(el.title))]
                              )
                            : _vm._e(),
                          _vm.isNavbarDropdown(el)
                            ? _c(
                                "b-nav-item-dropdown",
                                {
                                  attrs: { href: el.href, text: el.title },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClick(el)
                                    },
                                  },
                                },
                                _vm._l(el.elements, function (eld) {
                                  return !eld.hide
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          key: eld.title,
                                          attrs: { href: eld.href },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClick(eld)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(eld.title))]
                                      )
                                    : _vm._e()
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _vm._l(_vm.elementsRight, function (el) {
                    return !el.hide
                      ? _c(
                          "b-navbar-nav",
                          { key: el.title },
                          [
                            _c(
                              "b-nav-item",
                              {
                                attrs: { href: el.href },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClick(el)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(el.title))]
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm.helpUrl.length > 0
                    ? _c(
                        "b-nav-item",
                        { attrs: { href: _vm.helpUrl, target: "_blank" } },
                        [_c("b-icon-question-circle-fill")],
                        1
                      )
                    : _vm._e(),
                  _vm.showUserBox && !_vm.showOnlineStatus
                    ? _c(
                        "b-nav-item-dropdown",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [_c("b-icon-person-circle")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            456216729
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            { attrs: { href: "/account" } },
                            [_vm._v("Mein Account")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { href: "/logout" } },
                            [_vm._v("Abmelden")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showOnlineStatus
                    ? _c("b-nav-item", { attrs: { right: "" } }, [
                        _vm.online
                          ? _c(
                              "span",
                              { staticClass: "online" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "cloud"] },
                                }),
                                _c("span", [_vm._v(" online")]),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.online
                          ? _c(
                              "span",
                              { staticClass: "offline" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "cloud"] },
                                }),
                                _c("span", [_vm._v(" offline")]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }